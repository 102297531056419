import { useEffect, useState } from "react";
import ShopContextProvider from "./components/context/AppContext";
import Navbar from "./components/navbar/Navbar"
import Sidebar from "./components/sidebar/Sidebar";
import {Home, Categories, CategoriesIndex, SubCategoriesIndex, SubCategoriesCreate, BranchesIndex, BranchesCreate, ProductCreate, ProductIndex, CategoriesDetails, UserIndex, UserDetails, CategoriesEdit, ProductEdit, Investors, Riders, OrdersIndex, Staff, Messages, ProtectedRoutes, Login, Training} from "./pages/pages";

import { Route, Routes } from "react-router-dom";

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  
  useEffect(()=>{
    const data = JSON.parse(localStorage.getItem('user'))
    if(data){
      setIsLoggedIn(true)
    }},[isLoggedIn])

  return (
       <div className='home' >
        {isLoggedIn && <Sidebar/>}
        
        <div className="wrapper" >
        {isLoggedIn&&<Navbar/>}
         <div className="routes" style={{display:"flex", padding:"10px"}}>
          <ShopContextProvider>
          <Routes>
      
            <Route path="/" element={<ProtectedRoutes/>}>
              <Route index element={<Home />} />
              <Route path="training" element={<Training />} />
              <Route path="category" element={<Categories />} />
              <Route path="categories" element={<CategoriesIndex />} />
              <Route path="edit-categories/:id" element={<CategoriesEdit />} />
              <Route path="categories/:slug" element={<CategoriesDetails />} />
              <Route path="createsubcategory" element={<SubCategoriesCreate />} />
              <Route path="subcategories" element={<SubCategoriesIndex />} />
              <Route path="createbranch" element={<BranchesCreate />} />
              <Route path="branches" element={<BranchesIndex />} />
              <Route path="createproducts" element={<ProductCreate />} />
              <Route path="products" element={<ProductIndex />} />
              <Route path="products-edit/:id" element={<ProductEdit />} />

              <Route path="users" element={<UserIndex />} />
              <Route path="investors" element={<Investors />} />
              <Route path="riders" element={<Riders />} />
              <Route path="staff" element={<Staff />} />
              <Route path="orders" element={<OrdersIndex />} />
              <Route path="orders" element={<OrdersIndex />} />
              <Route path="userdetails" element={<UserDetails />} />
              <Route path="messages" element={<Messages />} />
            </Route>
            <Route path="login" element={<Login />} />
          </Routes>
          </ShopContextProvider>
          </div>
          {/* <Footer/> */}
          </div>
          </div>
    
  );
}

export default App;
